import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "React Imported SVG as CSS Background",
  "author": "tzookb",
  "type": "post",
  "date": "2019-07-28T16:53:29.000Z",
  "url": "/2019/07/react-imported-svg-as-css-background/",
  "medium_post": ["O:11:\"Medium_Post\":11:{s:16:\"author_image_url\";N;s:10:\"author_url\";N;s:11:\"byline_name\";N;s:12:\"byline_email\";N;s:10:\"cross_link\";N;s:2:\"id\";N;s:21:\"follower_notification\";N;s:7:\"license\";N;s:14:\"publication_id\";N;s:6:\"status\";N;s:3:\"url\";N;}"],
  "categories": ["How To"],
  "tags": ["javascript", "react", "svg"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{` ** You will need to have `}{`“`}{`styled-components`}{`”`}{` `}</p>
    <pre><code parentName="pre" {...{}}>{`import { renderToStaticMarkup } from 'react-dom/server';

const DividerWave = styled.div\`
  background: \${\`url("data:image/svg+xml,\${
    encodeURIComponent(renderToStaticMarkup(&lt;BoiledEggSvg /&gt;))
  }")\`}
\`
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      